// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$font-family-base: "Roboto", sans-serif !default;

/**
 * core
 */
@import "scss/settings";
@import "scss/mixins";
@import "scss/core";
@import "scss/typography";
@import "scss/intake";

/**
* custom
*/
@import "scss/responsive.scss";
@import "scss/components/button.scss";
@import "scss/components/segments.scss";
@import "scss/components/checkbox.scss";
@import "scss/components/radio.scss";
@import "scss/components/alert.scss";
@import "scss/components/input.scss";
@import "scss/components/textarea.scss";
@import "scss/components/dropdown.scss";
@import "scss/components/select-alert.scss";
@import "scss/components/panel.scss";
@import "scss/components/breadcrumbs.scss";
@import "scss/components/toast.scss";
@import "scss/components/mobile-header.scss";

ion-router-outlet.dev-mode {
  height: calc(100% - 50px);
}
.modal-fullscreen{
  position: fixed;
}
ion-toolbar > ion-title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 90px 1px;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: inherit;
  color: var(--ion-color-light);
  font-size: 30px;
  font-weight: 700;
  border-bottom: none;
}

ion-toolbar > ion-menu-button {
  z-index: 2;
  color: #333132;
  font-weight: 700;
  --background-focused: rgba(66, 66, 66, 0.24);
  --background-hover: rgba(66, 66, 66, 0.08);
  --border-radius: 50%;
  --color: initial;
  --padding-end: 8px;
  height: 40px;
  font-size: 48px;
}

.orange-button {
  --background: #f78d2b !important;
}

.orange-text {
  color: #f78d2b !important;
}

.list-ios.list-inset ion-item {
  --border-width: 0;
  --inner-border-width: 0;
}

a {
  color: var(--ion-color-secondary);

  &:hover {
    color: #23B1DB;
  }

  &:visited {
    color: #129BC4;
  }
}

.sidemenu-bottom {
  background: var(--ion-color-primary);
}

.modal-wrapper {
  box-shadow: none !important;
}

.fullscreen-modal {
  background: var(--ion-color-primary);
  --width: 100%;
  --height: 100%;
  box-shadow: 0 28px 48px rgba(0,0,0,0.4) !important;
}

.two-fa-modal{
  .modal-wrapper{
    max-width: 450px;
    box-shadow: 0 28px 48px rgba(0,0,0,0.4) !important;
 }
}

ion-backdrop {
  background: white;
}

.scrollbar, .mat-select-panel {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 15px;
    background: var(--ion-color-primary);
    border-radius: 26px;
  }

  /* Track */
  &::-webkit-scrollbar-thumb {
    background: #687984;
    border-radius: 26px;
    height: 44px;
    border: 2px solid var(--ion-color-primary);
  }
}

.password-icons-wrapper {
  text-align: right;
  padding-top: 2px;
  width: 90%;
  position: absolute;

  .password-input-icon {
    margin-right: 10px;
    color: var(--ion-color-gray-dusty);
    z-index: 99;
    position: relative;
  }
}

.show-modal {
  position: fixed;
}

.alert-input.sc-ion-alert-md {
  color: lightgray;
}

ion-toast {
  position: fixed;
}

ion-toast.toast-comming-soon {
  position: fixed;
  text-align: center;
}

.erase-btn {
    border-radius: 50px;
    width: 64px;
    height: 64px;
}

.index-top {
  z-index: 999 !important;
}

.light-font-alert  .alert-message {
  text-align: center;
}

#menu-content {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: auto;
}
