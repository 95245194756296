// http://ionicframework.com/docs/theming/
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@media (min-width: 768px) {
  body {
    overflow-y: auto !important;
    position: unset;
    background: var(--ion-color-primary);

    &::-webkit-scrollbar {
      width: 15px;
      background: var(--ion-color-primary);
      border-radius: 26px;
    }
  
    /* Track */
    &::-webkit-scrollbar-thumb {
      background: #687984;
      border-radius: 26px;
      height: 44px;
      border: 2px solid var(--ion-color-primary);
    }
  }
}

.alert-scroll {
  overflow-y: auto;
  max-height: 240px;
}

.alert-radio-group::-webkit-scrollbar {
  width: 3px;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.alert-message {
  &.sc-ion-alert-md {
    color: lightgray !important;
  }
}

.modal-wrapper{
  background: transparent !important;
}

.two-fa-modal{
  .modal-wrapper{
    border-radius: 15px !important;
 }
}

ion-loading {
  z-index: 15 !important;
}

ion-button{
  outline: none;
}

ion-avatar{
  outline: none;
}

ion-back-button button {
  padding-left: 0 !important;
}

.larger-hamburger-menu {
  font-size: 20px !important;
}

/* removes the faint 'active' highlight that happens only on native after long-press */
ion-item {
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
}

/* removes number up/down arrows from numeric inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.disable-modal{
  --height:80%;
  --border-radius:10px;
  padding:25px;
}
/* ion modal auto-height from https://github.com/ionic-team/ionic-framework/issues/16852#issuecomment-449433452 */
ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

.token-modal-css .modal-wrapper {
  height: 80%;
  top: 10%;
  max-width: 488px;
  position: absolute;
  display: block;
  border-radius: 15px;
}

.delete-modal-css .modal-wrapper {
  height: 80%;
  top: 10%;
  max-width: 488px;
  position: absolute;
  display: block;
  border-radius: 15px;
}

.ach-modal-css .modal-wrapper {
  height: 80%;
  top: 10%;
  width: 700px;
  position: absolute;
  display: block;
  border-radius: 15px;
}


ion-popover.unavailable-service-popover-custom-class {
  --max-width: 180px;

  ion-backdrop {
    opacity: 0 !important;
  }
}
// Big modals
ion-modal.big-modal.desktop {
  --height: 85%;
  --border-radius: 30px;
}

ion-alert .alert-wrapper h2 {
  text-align: center!important;
}