ion-button {
    --box-shadow: 0 0 transparent !important;

    &.primary, &.secondary {
        border-radius: 26px;
        font: normal normal bold 16px/21px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-transform: capitalize;
        height: 51px;
    }

    &.primary {
        --background: transparent linear-gradient(299deg, #314452 0%, var(--ion-color-primary) 100%) 0% 0% no-repeat padding-box;
        --background-hover: #3C505F 0% 0% no-repeat padding-box;
    }

    &.secondary {
        --background: #23B1DB;
        --background-hover: var(--ion-color-secondary);
        color: var(--ion-color-primary);
    }

    &.primary:active, &.secondary:active {
        border: 1.5px solid #43caf1;
    }

    &.secondary.transparent {
        --background: transparent;
        --background-hover: transparent;
        color: var(--ion-color-secondary);
        border: 1px solid var(--ion-color-secondary);

        &:hover {
            border: 1px solid #23B1DB;
        }

        &.button-disabled {
            --background: transparent;
            border: 1px solid var(--ion-color-primary-tint);
            color: var(--ion-color-primary-tint);
        }
    }

    &.glow {
        box-shadow: 2px 11px 19px #0B171F, -5px -5px 19px rgba(255, 255, 255, 0.3);
    }

    &.no-border {
        border: 0 !important;
        color: var(--ion-color-primary-tint) !important;
    }
}

/* Additional button */

div.two-row-btn {
    padding: 27px 33px 33px 33px;
    border: 1px solid #687984;
    border-radius: 25px;
    background: transparent;
    cursor: pointer;

    & span:nth-child(1) {
        text-align: left;
        font: normal normal bold 16px/21px Roboto;
        letter-spacing: 0px;
        color: var(--ion-color-light);
        text-transform: uppercase;
        opacity: 0.4;
        display: block;
    }

    & span:nth-child(2) {
        text-align: left;
        font: normal normal normal 20px/26px Roboto;
        letter-spacing: 0px;
        color: var(--ion-color-light);
    }

    & ion-icon {
        position: absolute;
        right: 26px;
        color: var(--ion-color-light);
        font-size: 25px;
    }

    &:hover {
        background: var(--ion-color-primary) 0% 0% no-repeat padding-box;
    }

    &:active {
        background: var(--ion-color-primary) 0% 0% no-repeat padding-box;
        border: 1px solid var(--ion-color-secondary);
    }

    &[disabled="true"] {
        opacity: 0.5;
        pointer-events: none;
    }
}
