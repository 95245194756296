h1, h2, h3, h4, h5, h6 {
  color: var(--ion-color-light);
}

h1 {
  font-size: rem-calc(24);
  font-weight: 400;
}

p {
  text-align: left;
  color: var(--ion-color-light);
}

.title-line-one, .title-line-two {
  display: block;
  text-align: left;
  margin: 0;
  padding: 0;
}

.title-line-one {
  color: var(--ion-color-light);
}

.title-line-two {
  color: var(--ion-color-secondary);
  font-weight: bold;
}