ion-alert {
    .alert-wrapper {
        border-radius: 26px !important;

        h2 {
            text-align: left;
            letter-spacing: 0px;
            color: #FFFFFF !important;
        }

        .alert-radio-group {
            width: 80%;
            margin-left: 10%;
            border-bottom: 0;
            border-top: 1px solid var(--ion-color-primary-tint);
            padding-top: 15px;

            button {
                right: 26px;
                .alert-button-inner{
                    min-height: 22px;
                }

                .alert-radio-label {
                    padding: 0px;
                    margin-left: 42px;
                    color: var(--ion-color-primary-tint);
                }

                &[aria-checked="true"] {
                    .alert-radio-inner {
                        background-color: var(--ion-color-secondary);
                        width: 18px;
                        height: 18px;
                        left: -1px;
                        top: -1px;
                    }

                    .alert-radio-label {
                        color: var(--ion-color-light) !important;
                    }
                }

                .alert-radio-icon {
                    border: 1px solid #687984;
                }
            }
        }

        .alert-button-group {
            padding: 32px 20% 10px 20%;

            .alert-button {
                display: block;
                color: #FFFFFF;
                width: 100%;
                text-align: center;
                border-radius: 26px;
    
                &:nth-child(2) {
                    background: transparent linear-gradient(301deg, #314452 0%, #23333E 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 2px 11px 19px #0B171F, -5px -5px 19px rgba(255, 255, 255, 0.3);
                    margin-bottom: 15px;
                }
    
    
                span {
                    justify-content: center;
                    letter-spacing: 0px;
                }
            }
        }
    }
}

.custom-alert{
    .alert-wrapper{
      .alert-head{
        h2{
          text-align: center !important;
          color: #23b1db !important;
          font-size: 19px !important;
          font-weight: 600 !important;
        }
      }
      .alert-message {
        text-align: center !important;
        font-size: 15px !important;
        margin-top: 14px !important;
        padding-bottom: 0 !important;
      }
      .alert-button-group{
        .alert-button {
          background: #22b0db !important;
          color: black !important;
          margin-bottom: 12px !important;
        }
      }
    }
  
  }

.custom-select {
    ion-list {
        border: 1px solid #71848F;
        border-radius: 25px;
        padding: 0;

        ion-select {
            color: var(--ion-color-primary-tint);
            position: relative;
            right: 10px;
        }
    
        ion-item {
          --background: transparent;
          background: transparent;
          border: 0;
        }
    
        ion-label {
          color: var(--ion-color-primary-tint) !important;
          font-size: 15px !important;
          font-weight: 400;
          margin-left: 20px;
        }
    }
}
