/**
 * Core CSS
 * Note: Try to keep as lean as possible. Use a componentized approach to the css code.
 */
 
/**
 * DOM elements
 */
 a {
  text-decoration: none;
}

/**
 * attribute helpers
 * Note: All attributes must start with 'ui' prefix to avoid naming conflicts
 */
[ui-center] {
  text-align: center;
}
[ui-left] {
  text-align: left;
}
[ui-secondary] {
  color: var(--ion-color-blue-cerulean);
}
[ui-mt] {
  margin-top: rem-calc($default-spacing / 4);
}
[ui-mb] {
  margin-bottom: rem-calc($default-spacing / 4);
}
[ui-mb-2] {
  margin-bottom: rem-calc($default-spacing / 2);
}
[ui-iblock] {
  display: inline-block;
}
[ui-space-header-top] {
  margin-top: #{$default-spacing * 2}px;
}
[ui-scroll] {
  overflow-y: auto;
  max-height: 36vh;
}
[ui-no-space] {
  margin: 0;
  padding: 0;
}
[ui-no-space-b] {
  margin-bottom: 0;
  padding-bottom: 0;
}
[ui-no-space-t] {
  margin-top: 0;
  padding-top: 0;
}
[ui-text-warning] {
  color: var(--ion-color-orange-hotcinnamon);
}
[ui-flex-vert-center] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
[ui-add-container-pad] {
  padding-left: 20px;
  padding-right: 20px;
}


[ui-list] {
  display: table;

  > div {
    display: table-row;

    > div {
      display: table-cell;
    }
    
    [ui-list-label] {
      text-align: left;
      padding-right: 20px;
      font-size: 14px;
      font-weight: 500;
    }
    [ui-list-value] {
      text-align: left;
      word-break: break-all;
      font-weight: 300;
      font-size: 14px;
    }
  }
}

/**
 * General common elements
 */
.button-solid {
  --box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.16);
}
 
.welcome-card img {
  max-height: 35vh;
  overflow: hidden;
}

.agx-logo {
  max-width: 205px;
  display: inline-block;
  margin: 0;
}

.inner-wrapper {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
