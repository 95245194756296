ion-item {
  ion-textarea {
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    min-height: 51px;
    --background: transparent;
    --padding-start: 20px !important;
    --padding-top: 12px !important;

    &.default {
      --color: var(--ion-color-primary-tint);
      --placeholder-color: var(--ion-color-primary-tint);
      border: solid 1px var(--ion-color-primary-tint);
      border-radius: 26px;
    }

    &.filled {
      --color: #FFFFFF;
      --placeholder-color: var(--ion-color-primary-tint);
      border: solid 1px var(--ion-color-primary-tint);
      border-radius: 26px;
    }

    &.focused {
      --color: #FFFFFF;
      --placeholder-color: #FFFFFF;
      border: solid 1px #43caf1;
      border-radius: 26px;
    }

    &.error {
      --color: #FFFFFF;
      --placeholder-color: #FFFFFF;
      border: solid 1px var(--ion-color-danger);
      border-radius: 26px;
    }
  }
}
